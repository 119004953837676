<template>
	<div class="page-main">
		<div class="login-header">
			<div class="container-inner">
				<div class="c-logo fl">
					<a href="https://bd.ruiyi126.com/" class="c-logo-img">
						<img src="../../assets/images/logo.png" alt="瑞蚁云表单">
					</a>
				</div>
				<div class="c-btngroup fr">
					<router-link to="/login"><el-button type="primary" size="small">登录</el-button></router-link>
					<a href="https://bd.ruiyi126.com/" class="b-btn b-blue-primary">返回首页</a>
				</div>
			</div>
		</div>
		<div class="login-main">
			<div class="login-inner">
				<div class="login-infor">
					<h2>瑞蚁云表单 · 后台管理注册</h2>
					<el-form class="login-form" ref="form" :model="form" :rules="rules" label-width="1px">
						<el-form-item label="用户名">
							<el-input v-model="form.name" placeholder="请输入用户名"></el-input>
						</el-form-item>
						<el-form-item label="手机号">
							<el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="验证码">
							<el-input v-model="form.smscode" placeholder="请输入验证码">
								<i slot="append" class="" v-if="!sendState" @click="getSmscode">获取验证码</i>
								<i slot="append" class="" v-else>{{time}}秒后从新获取验证码</i>
							</el-input>
						</el-form-item>
						<el-form-item label="密码">
							<el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码">
							<el-input v-model="form.confirm" type="password" placeholder="请输入确认密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码">
							<el-checkbox v-model="checkState">我接受<span class="font-blue">《云表单服务协议》</span></el-checkbox>
						</el-form-item>
						<el-form-item class="login-btns">
							<el-button type="primary" class="login-btn" @click="register('form')">注册</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		
		<p class="login-footer">Copyright © 2019 jinrf.com All Rights Reserved. 北京金瑞帆科技有限公司 京ICP备05027158号</p>
	</div>
</template>
<script>
import { post} from "/src/services/ajax_ucenter.js";
export default {
	components: {
	},
	data(){
		var checkAccount = (rule, value, callback) => {
          if (!value) {
            return  callback(new Error("用户名不能为空"));
          }else{
            let regx = /^[A-Za-z0-9_\-\u4e00-\u9fa5\s]+$/;
            if(!(regx.test(value) )){
              return callback(new Error('可输入汉字或字母数字！'));
            }else{
              return callback();
            }
          }
        };
		var checkPassword = (rule, value, callback) => {
          if (!value) {
            return  callback(new Error("密码不能为空"));
          }else{
            let regx = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])[0-9a-zA-Z!@#$%^&*()]{8,16}/;
            if(!(regx.test(value) )){
              return callback(new Error('密码必须包含字母，数字，特殊符号(!@#$%^&*)，且在8~16之间'));
            }else{
              return callback();
            }
          }
        };
		var checkConfirm = (rule, value, callback) => {
          if (!value) {
            return  callback(new Error("确认密码不能为空"));
          }else{
            
            if(value != this.form.password){
              return callback(new Error('两次输入密码不一致'));
            }else{
              return callback();
            }
          }
        };
		return {
			loginType:1,
			flag:false,
			sendState:false,
			time:60,
			checkState:true,
			form:{
				name:'',
				phone:'',
				smscode:'',
				password:'',
				confirm:''
			},
			rules: {
				name: [
					{ validator: checkAccount, trigger: 'blur' },
				],
				password: [
					{ validator: checkPassword, trigger: 'blur' }
				],
				confirm: [
					{ validator: checkConfirm, trigger: 'blur' }
				],
			}
		}
	},
	watch: {
	},
	mounted(){
		
	},
	methods: {
		// 获取验证码
		getSmscode(){
			const _this = this;
			if(_this.form.phone){
				post('/formadmin/send_sms.jhtml',{mobile:_this.form.phone}).then(res => {
					if(res.code == 200){
						_this.sendState = true;
						_this.countDown();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}else{
				_this.$message.error("请输入手机号")
				
			}
		},
		countDown(){
			const _this = this;
			var timer = setInterval(() => {
				if(_this.time>0){
					_this.time--;
				}else{
					clearInterval(timer);
					_this.sendState = false;
				}
			}, 1000);
			
		},
		// 获取表单分享设置
		register(form){
			const _this = this;
			if(_this.checkState){
				this.$refs[form].validate((valid) => {
					if (valid) {
						post('/formadmin/o_register.jhtml',this.form).then(res => {
							if(res.code == 200){
								_this.$message.success("注册成功！");
								_this.$router.push("/login")
							}else{
								_this.$message.error(res.message);
							}
						}).catch(err => {
							_this.$message.error(err);
						})
					}else{
						_this.$message.error("登录失败");
					}
				});
			}else{
				_this.$message.error("请选择云表单服务协议");
			}
			
			
		},
		// 拼图成功
		onSuccess (){
			const _this = this;
			_this.flag = false;
			post('/formadmin/login.jhtml',this.form).then(res => {
				if(res.code == 200){
					_this.$router.push("/")
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 拼图失败
		onFail (){
		this.$refs.slideblock.reset();
		},
		// 拼图刷新
		onRefresh (){
		this.$refs.slideblock.reset();
		},
	}
};
</script>
<style lang="less" scoped>
	.page-main {
		background: #f3f5f6;
		background: url(../../assets/images/login_bg.jpg) no-repeat center;
		display: flex;
		flex-direction: column;
		flex:1;
		.login-header{
			background: #fff;
			padding:18px 23px;
			text-align: center;
			border-bottom:1px solid #eee;
			.container-inner{
				width: 1200px;
				margin: 0 auto;
			}
			.c-logo-img{
				display: inline-block;
				vertical-align: middle;
			}
			.c-btngroup{
				display: flex;
				.b-btn{
					border:1px solid var(--theme-color);
					border-radius:4px;
					font-size: 14px;
					display: inline-block;
					text-align: center;
					width:78px;
					height:32px;
					line-height: 32px;
					margin-left:15px;
					color:var(--theme-color);background: #fff;
					&:hover{
						background: var(--theme-color);
						color:#fff;
					}
				}
			}
		}
		.login-footer{
			text-align: center;
			color:#999;
			padding-bottom: 10px;
		}
		.login-main{
			width:100%;
			position: relative;
			flex: 1;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.login-inner{
				margin:30px auto;
				width:1105px;
				height:520px;
				background:url(../../assets/images/login_img.jpg) #fff no-repeat left center;
				border-radius:18px;
				box-shadow: 0 0 11px 8px rgba(194,194,194,0.32);
				position: relative;
				.login-infor{
					padding:35px 70px 0;
					position: absolute;
					right:0;
					top:0;
					width: 48%;
					h2{
						font-size: 28px;
						color:#333;
						line-height: 1.5;
						text-align: center;
						padding-bottom: 26px;
					}
					.login-form{
						// position: absolute;
						// left:52%;
						// top:50%;
						// transform: translateY(-50%);
						// width: 430px;
						// margin:0 52px;
						::v-deep .el-form-item{
							margin-bottom:10px;
							.el-form-item__label{
								display: none;
							}
							.el-form-item__content{
								.el-input__inner{
									height:46px;
									font-size: 14px;
									line-height: 46px;
								}
							}
						} 
						.login-btns{
							border:none;
							.login-btn{
								width:100%;
								font-size: 16px;
								box-sizing: border-box;
								&:hover{
									background:#1eb1e2;color:#fff;
								}
							}
						}
					}
				}
			}
		}
		.graph_code {
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			background: rgba(0,0,0,.3);
			z-index: 99;
		}

		.slidercaptcha {
			width: 280px;
			padding: 20px 18px 24px;
			margin-left: -158px;
			margin-top: -157px;
			background: #fff;
			box-shadow: 0 1px 4px rgba(0,0,0,.3);
			border-radius: 4px;
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 1;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
			animation: openGraph .5s;
			// ::v-deep .slide-verify-slider-mask{
			// 	background: var(--theme-color);
			// }
			// ::v-deep .slide-verify-slider-mask-item:hover{
			// 	background: var(--theme-color);
			// }
		}
	}
</style>